<template>
  <div>
    <div class="content">
      <ActionFlow
        select_status="done"
        post_status="active"
        complete_status="active"
      />
      <ExplainLink />
      <div class="main">
        <p>
          この宅配ボックスは商品発送エリアに対応しておりません。
        </p>
        <p>
          ※対応エリアは<a href="https://smari.io/terms#area" target="_blank" class="area-link is-underlined">こちら</a>
        </p>
        <div class="deliverybox-info">
          <p>
            {{ areaError.store_address }}<br />
            {{ areaError.store_name }}&nbsp;&nbsp;{{ areaError.store_locker_name }}
          </p>
        </div>
      </div>
      <div class="sub">
        <p>
          ローソンなどに設置しているスマリボックスなど、他の発送方法をご利用ください。<br />
          スマリボックス設置拠点については
          <a href="https://smari.io/location" target="_blank" class="area-link is-underlined">こちら</a><br />
          <br />
          発送しない方はブラウザを閉じてください。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import DeliveryboxRepository from "../repositories/DeliveryboxRepository"
import ActionFlow from "../components/elements/ActionFlow.vue"
import ExplainLink from "../components/elements/ExplainLink.vue"

export default {
  name: "StoreAreaError",
  components: {
    ActionFlow,
    ExplainLink
  },
  async created() {
    const token = this.$route.query.token
    if (!token) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }
    DeliveryboxReceptionRepository.findByToken(token)
      .then(() => {
        this.is_allow_action = true
      })
      .catch(() => {
        this.error_message =
          "事前受付が期限切れとなっています。最初から操作を行ってください。\nまたは、既に発送受付処理が完了している商品の可能性があります。ご確認ください。"
      })
  },
  data() {
    return {
      token: this.$route.query.token,
    }
  },
  computed: {
    areaError() {
      return this.$store.state.area_error
    },
  },
  methods: {
  },
}
</script>

<style scoped>
.main {
  font-weight: 600;
}
.deliverybox-info {
  padding-top: 10px;
  text-align: center;
}
.area-link {
  color: #092c0b;
}
.sub {
  margin-top: 20px;
}
</style>
